var PubnubMessageQueue = require('./PubnubMessageQueue')
var PercentArrayFilter = require('./PercentArrayFilter')
var EventEmitter = require('events')
var SubtitleChunkPlayer = require('./SubtitleChunkPlayer')

var DATA_EVENT = 'data'
var emitter = new EventEmitter();
var pubnub = null
var pubnubChannelId = null
var clientMessageHandlerVersion = 0

function sendQueue () {
  PubnubMessageQueue.startSending(pubnubChannelId)
  var messages = PubnubMessageQueue.getMessages(pubnubChannelId)
  var filteredMessages = PercentArrayFilter.filterIntermediateValues(messages)
  var filteredPercents = []
  for (var i = 0; i < filteredMessages.length; i++) {
    filteredPercents.push(filteredMessages[i].value)
  }
  var message = {
    src: pubnub.getUUID(),
    percents: filteredPercents,
    values: filteredMessages,
    ver: 2
  }

  if (!pubnubChannelId) {
    // Do not send data if not connected to a pubnub channel
    return
  }

  console.log('Sending data to pubnub: ', message)
  PubnubMessageQueue.reset(pubnubChannelId)
  pubnub.publish({
      channel : pubnubChannelId,
      message : message,
    },
    function () {
      PubnubMessageQueue.endSending(pubnubChannelId)
      if (!PubnubMessageQueue.isEmpty(pubnubChannelId)) {
        sendQueue()
      }
    }
  )
}

function report_v1 (message) {
  var percents = message.percents
  for (var i = 0; i < percents.length; i++) {
    var percent = percents[i]
    emitter.emit(DATA_EVENT, percent)
  }
}

function report_v2 (message) {
  var values = message.values
  for (var i = 0; i < values.length; i++) {
    var value = values[i]
    var deviceName = value.from ? value.from.name : null
    var percent = value.value
    emitter.emit(DATA_EVENT, percent, deviceName)
  }
}

function askPubnubHereNow (channelName) {
  pubnub.hereNow({
      channels: [channelName],
      includeUUIDs: true,
      includeState: true,
    },
    function (status, response) {
      var occupants = response.channels[channelName].occupants
      console.log('hereNow result: ', occupants)
      for (var i = 0; i < occupants.length; i++) {
        // Check if mobile client can handle subtitle chunk messages
        if (occupants[i] && occupants[i].state && occupants[i].state.messageHandlerVersion) {
          clientMessageHandlerVersion = occupants[i].state.messageHandlerVersion
        }
      }
    }
  )
}

var hereNowTimeout = null
var pubNubMessageListener = {
  message: function (m) {
    var message = m.message
    if (message.src === pubnub.getUUID()) {
      // don't handle own messages
      return
    }

    if (message.ver == '1.0') {
      report_v1(message)
    } else if (message.ver == 2) {
      report_v2(message)
    }
  },
  presence: function(presenceEvent) {
    if (presenceEvent.action == 'join') {
      // In case if mobile app has just joined
      // resend play message on next subtitle
      SubtitleChunkPlayer.reset()

      // Request app version in status in order
      // to know how to send sibtitle messages
      clearTimeout(hereNowTimeout)
      hereNowTimeout = setTimeout(function () {
        askPubnubHereNow(presenceEvent.channel)
      }, 2000)
    }
  }
}

function connect (pubnubObj, drsRoomName) {
  pubnub = pubnubObj
  pubnubChannelId = drsRoomName

  // Connect to pubnub channel
  pubnub.subscribe({
    channels: [pubnubChannelId],
    withPresence: true,
  })

  pubnub.addListener(pubNubMessageListener)
  askPubnubHereNow(pubnubChannelId)
  console.log('joined room / pubnub channel ', pubnubChannelId)
}

function disconnect () {
  // Remove listener
  pubnub.removeListener(pubNubMessageListener)

  // Disconnect from pubnub channel
  pubnub.unsubscribe({
    channels: [pubnubChannelId],
  })
  pubnubChannelId = null
}

function sendSingleSignal (percentValue, deviceId, motorNumber) {
  var value = {
    value: percentValue,
    to: {
      id: deviceId,
      motor: motorNumber,
    }
  }
  PubnubMessageQueue.push(pubnubChannelId, value)

  if (!PubnubMessageQueue.isSendingInProgress(pubnubChannelId)) {
    sendQueue(pubnubChannelId)
  }
}

function send (percentValue, deviceId, motorNumber, positionMsec, subtitles) {
  if (subtitles && clientMessageHandlerVersion >= 3) {
    // In case if these are subtitles and if the mobile app version supports it,
    // we send a subtitle chunk
    SubtitleChunkPlayer.play(positionMsec, subtitles, pubnub, pubnubChannelId)
  } else {
    // Otherwise just send a single signal
    sendSingleSignal(percentValue, deviceId, motorNumber)
  }
}

function subscribe (callback) {
  emitter.on(DATA_EVENT, callback);
}

function unsubscribe (callback) {
  emitter.removeListener(DATA_EVENT, callback);
}

module.exports = {
  /**
   * Connect to PubNub room
   */
  connect: connect,

  /**
   * Disconnect from PubNub room
   */
  disconnect: disconnect,

  /**
   * Send signal to mobile app
   * @param percentValue
   * @param deviceId
   * @param motorNumber
   * @param positionMsec
   * @param subtitles
   */
  send: send,

  /**
   * Subscribe for data events coming from mobile app
   */
  subscribe: subscribe,
  /**
   * Unsubscribe from data events coming from mobile app
   */
  unsubscribe: unsubscribe
}
