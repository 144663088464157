var env = 'prod'

var settingsProd = {
    apiUrl: 'https://api.feel-app.com',
    subtitlesUrl: 'https://api.pibds.com/api/v1',
    partnerToken: '',
    userId: '',
    PUBNUB_CONFIG: {
        publish_key: 'pub-c-2859d4b9-7f64-428d-85f3-02aeef648325',
        subscribe_key: 'sub-c-296aa490-f663-11e5-ba5f-0619f8945a4f'
    }
};

var settingStaging = {
    apiUrl: 'https://api-feel-app-staging.herokuapp.com/',
    subtitlesUrl: 'https://api-pibs-com-staging.herokuapp.com/api/v1',
    partnerToken: '',
    userId: '',
    PUBNUB_CONFIG: {
        publish_key: 'pub-c-2859d4b9-7f64-428d-85f3-02aeef648325',
        subscribe_key: 'sub-c-296aa490-f663-11e5-ba5f-0619f8945a4f'
    }
};

var settingsDev = {
    apiUrl: 'http://0.0.0.0:5000',
    partnerToken: '',
    userId: '',
    PUBNUB_CONFIG: {
        publish_key: 'pub-c-92616af2-95ea-421d-8286-11e462585afc',
        subscribe_key: 'sub-c-3f203b78-e5d9-11e5-a027-0619f8945a4f'
    }
};

var settings = settingsProd;
if (env === 'dev') {
    settings = settingsDev;
} else if (env === 'staging') {
    settings = settingStaging;
}

module.exports = settings
