var zeptojs = require('zeptojs')
var Promise = require('promise')
var PubNub = require('pubnub')
var status = require('./Status')
var PubnubRoomConnection = require('./PubnubRoomConnection')
var Subs = require('../subs/Subs')
var settings = require('./AppsSettings')

function getUserConnectionInfo() {
  return new Promise(function (resolve, reject) {
    var userId = settings.userId
    var url = settings.apiUrl + '/internal-api/v1/user/' + userId +
      '/drs?partner_token=' + settings.partnerToken

    zeptojs.ajax({
      type: 'get',
      url: url,
      success: function (response) {
        try {
          var result = JSON.parse(response)
          resolve(result)
        } catch(e) {
          reject('Server responded with invalid JSON: ' + response)
        }
      },
      error: function (err) {
        console.log('Error loading drs settings from feel server: ', err)
        reject(err)
      }
    })
  })
}

function init(feelAppsToken, userId, onDevicesChanged, pubnubUUID) {
  var pubnub = new PubNub({
    subscribe_key : settings.PUBNUB_CONFIG.subscribe_key,
    publish_key: settings.PUBNUB_CONFIG.publish_key,
    // Should be unique
    uuid: pubnubUUID,
    // See https://www.pubnub.com/community/discussion/623/javascript-https-problem
    ssl : ('https:' == document.location.protocol),
    presenceTimeout: 5,
  })

  Subs.setPubNub(pubnub)

  status.init(pubnub, settings, onDevicesChanged)

  getUserConnectionInfo()
    .then(function (drsInfo) {
      var drsRoomName = drsInfo.drs_room.drs_id
      PubnubRoomConnection.connect(pubnub, drsRoomName)
    }).catch(function (err) {
      console.log(err)
    })
}

function destroy () {
  PubnubRoomConnection.disconnect()
  status.disconnect()
  settings.partnerToken = null
  settings.userId = null
}

function playSubtitle (percentValue, positionMsec, subtitles) {
  PubnubRoomConnection.send(percentValue, null, null, positionMsec, subtitles)
}

function getMobileAppLauchUrl (requestToken) {
  return'feelapp://authorize?token=' + encodeURIComponent(requestToken)
}

module.exports = {
  init: init,
  destroy: destroy,
  playSubtitle: playSubtitle, // alias for send
  status: status,
  data: PubnubRoomConnection,
  getMobileAppLauchUrl: getMobileAppLauchUrl,
}
