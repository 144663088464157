/**
 * Find subtitles which fall into the time range
 * @param {*} subtitles - array of subtitle objects with fields:
 *  time - time in milliseconds from beginning of the video
 *  value - subtitle value 0..4
 *  Sorted by time field
 * @param {*} startPositionSeconds - time range begin
 * @param {*} durationSeconds - time range duration
 */
function getNextSubtitles (subtitles, positionMsec, durationMsec) {
  // TODO replace with O(Log(N)) algorithm
  // TODO however current implementation takes less than 1 msecond on 5K subtitles
  var firstSubtitleIndex = -1
  var lastSubtitleIndex = -1
  for (var i = 0; i < subtitles.length; i++) {
    var time = subtitles[i].time
    if (time <= positionMsec) {
      firstSubtitleIndex = i
    }
    if (time <= positionMsec + durationMsec) {
      lastSubtitleIndex = i
    }
  }

  return (firstSubtitleIndex >= 0 && lastSubtitleIndex >= 0)
    ? subtitles.slice(firstSubtitleIndex, lastSubtitleIndex + 1)
    : []
}

module.exports = {
  getNextSubtitles: getNextSubtitles,
}
