var pubnub = null
var subscribed = false

function init (pubnubObj) {
  pubnub = pubnubObj
  console.log('BillingPubnub initialized')
}

function play () {
  if (subscribed || !pubnub) {
    return
  }

  var pubnubChannelId = 'billing.' + pubnub.getUUID()
  console.log('BillingPubnub play(), channel id: ', pubnubChannelId)
  // Connect to pubnub channel
  pubnub.subscribe({
    channels: [pubnubChannelId],
  })
  subscribed = true
}

module.exports = {
  init: init,
  play: play,
}