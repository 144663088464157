var zeptojs = require('zeptojs')
var Promise = require('promise')
var EventEmitter = require('events')

var emitter = new EventEmitter()
var STATUS_EVENT_NAME = 'status'
var settings = null
var pubnub = null
var pubNubListener = null
var pubnubChannelId = null
var hereNowTimeout = null

var isOnline = false
var userDevices = []
var userDeviceDescriptions = []
var onDevicesChangedCallback = null

function reportPresence() {
  emitter.emit(STATUS_EVENT_NAME, {
    online: isOnline,
    devices: userDevices,
    deviceDescriptions: userDeviceDescriptions,
  })
}

function handlePresence (uuid, action) {
  if (uuid.indexOf('mobile-client') === 0) {
    // Mobile app presence
    if (action === 'join') {
      isOnline = true
      reportPresence()
      postDevicesToSubsServer()
      updateUserDevices()
    } else if (action === 'leave' || action === 'timeout') {
      isOnline = false
      reportPresence()
      postDevicesToSubsServer()
    }
  }
}

function postDevicesToSubsServer () {
  if (onDevicesChangedCallback) {
    onDevicesChangedCallback(isOnline ? userDevices : [])
  }
}

function updateUserDevices () {
  getUserDevices()
    .then(function (response) {
      var devices = response.devices
      if (typeof devices === 'string') {
        devices = JSON.parse(devices)
      } else if (devices === null) {
        devices = []
        userDeviceDescriptions = []
      }

      userDeviceDescriptions = response.device_descriptions
      if (userDeviceDescriptions == null) {
        userDeviceDescriptions = []
      }

      userDevices = devices
      reportPresence()
      postDevicesToSubsServer()
    }).catch(function (err) {
      console.log(err)
    })
}

function askPubnubHereNow (channelName) {
  pubnub.hereNow({
      channels: [channelName],
      includeUUIDs: true,
    },
    function (status, response) {
      var occupants = response.channels[channelName].occupants
      console.log('hereNow result: ', occupants)
      for (var i = 0; i < occupants.length; i++) {
        handlePresence(occupants[i].uuid, 'join')
      }
    }
  )
}

function subscribePubnubChannel (channelName) {
  pubnubChannelId = channelName

  // Initially tell that it's offline'
  emitter.emit(STATUS_EVENT_NAME, {
    online: false,
  })

  // Subscribe for channel notifications
  pubnub.subscribe({
    channels: [channelName],
    withPresence: true,
  })

  pubNubListener = {
    presence : function (m) {
      // Warnining - sometimes we don't get update
      // on mobile app presence here
      console.log('=== Presence: ', m)
      handlePresence (m.uuid, m.action)

      // Sometimes when we closed the app and
      // opened it in less than 5 seconds, the
      // timout event is generated after we joined
      // second time. In this case we need to
      // make sure that the mobile app is still connected
      // despite the timeout event.
      // We do it in 2 seconds because pubnub needs time
      // to update hereNow response.
      clearTimeout(hereNowTimeout)
      hereNowTimeout = setTimeout(function () {
        askPubnubHereNow(channelName)
      }, 2000)
    },
    message : function (m) {
      // Watch for user-authentication
      if (m.message.what === 'user-authentication') {
        console.log('User authentication detected')
        // Unsubscribe from the current channel
        pubnub.unsubscribe({channel : channelName})

        // Subscribe to the new pubnub channel
        connectToPubnub()
        updateUserDevices()
      } else if (m.message.what === 'user-devices-changed') {
        updateUserDevices()
      }
    }
  }

  pubnub.addListener(pubNubListener)

  // Get current presence
  isOnline = false
  reportPresence()
  postDevicesToSubsServer()
  askPubnubHereNow(channelName)
  clearTimeout(hereNowTimeout)
  hereNowTimeout = setTimeout(function () {
    // hereNow request sometimes return empty
    // result if requested immediately after
    // other parties joining.
    // So we give PubNub 10 seconds to
    // handle all joined users and return a correct
    // list.
    askPubnubHereNow(channelName)
  }, 10000)
}

function subscribe (callback) {
  return emitter.on(STATUS_EVENT_NAME, callback)
}

function unsubscribe (callback) {
  return emitter.removeListener(STATUS_EVENT_NAME, callback)
}

function getPubnubChannel() {
  return new Promise(function (resolve, reject) {
    var userId = settings.userId
    var url = settings.apiUrl + '/jslib-api/v1/user/' + userId +
      '/pubnub?partner_token=' + settings.partnerToken

    zeptojs.ajax({
      type: 'get',
      url: url,
      success: function (response) {
        try {
          var result = JSON.parse(response)
          var channel = result.pubnub_channel
          resolve(channel)
        } catch(e) {
          reject('Server responded with invalid JSON: ' + response)
        }
      },
      error: function (err) {
        console.log('Error loading drs settings from feel server: ', err)
        reject(err)
      }
    })
  })
}

function getUserDevices () {
  return new Promise(function (resolve, reject) {
    var userId = settings.userId
    var url = settings.apiUrl + '/jslib-api/v1/user/' + userId +
      '/devices?partner_token=' + settings.partnerToken

    zeptojs.ajax({
      type: 'get',
      url: url,
      success: function (response) {
        try {
          var result = JSON.parse(response)
          resolve(result)
        } catch(e) {
          reject('Server responded with invalid JSON: ' + response)
        }
      },
      error: function (err) {
        console.log('Error loading drs settings from feel server: ', err)
        reject(err)
      }
    })
  })
}

function connectToPubnub () {
  getPubnubChannel()
    .then(function (channel) {
      console.log('pubnub channel: ', channel)
      subscribePubnubChannel(channel)
    }).catch(function (err) {
      console.log(err)
    })
}

function disconnect () {
  clearTimeout(hereNowTimeout)
  pubnub.removeListener(pubNubListener)
  pubnub.unsubscribe({
    channels: [pubnubChannelId],
  })
}

function init(pubnubObj, newSettings, onDevicesChanged) {
  settings = newSettings
  pubnub = pubnubObj
  onDevicesChangedCallback = onDevicesChanged
  connectToPubnub()
  updateUserDevices()
}

module.exports = {
  init: init,
  disconnect: disconnect,
  subscribe: subscribe,
  unsubscribe: unsubscribe,
}