var sendingInProgressPerChannel = {}
var queuePerChannel = {}

function initProgress (channel) {
  if (!(channel in sendingInProgressPerChannel)) {
    sendingInProgressPerChannel[channel] = false
  }
}

function initQueue (channel) {
  if (!(channel in queuePerChannel)) {
    queuePerChannel[channel] = []
  }
}

function push (channel, message) {
  initQueue(channel)
  queuePerChannel[channel].push(message)
}

function isSendingInProgress (channel) {
  initProgress(channel)
  return sendingInProgressPerChannel[channel]
}

function startSending (channel) {
  initProgress(channel)
  sendingInProgressPerChannel[channel] = true
}

function endSending (channel) {
  initProgress(channel)
  sendingInProgressPerChannel[channel] = false
}

function getMessages (channel) {
  initQueue(channel)
  return queuePerChannel[channel]
}

function reset (channel) {
  queuePerChannel[channel] = []
}

function isEmpty (channel) {
  initQueue(channel)
  return queuePerChannel[channel].length === 0
}

module.exports = {
  push: push,
  isSendingInProgress: isSendingInProgress,
  startSending: startSending,
  getMessages: getMessages,
  reset: reset,
  isEmpty: isEmpty,
  endSending: endSending,
}