/**
 * Parse subtitle string into subtitle object
 * @param subtitleString - subtitle obtained from the server
 * @return subtitle object in the following format:
 *  1. Keys are subtitle time in seconds
 *  2. Values are Kiiroo values: 0..4
 * Example: '{"1.2": 1, "2": 4, "5.234": 0}' or
 *  '{1.2: 1, 2: 4, 5.234: 0}' are parsed into the object:
 *  {
 *    "1.2": 1,
 *    "2": 4,
 *    "5.234": 0
 *  }
 */
function parse(subtitleString) {
  // We must parse "relaxed" json as well, like {1.2: 1, 2: 4, 5.234: 0}
  var correctedString = subtitleString.replace(/\s/g, '');
  correctedString = correctedString.replace(/(['"])?([a-z0-9A-Z_\.]+)(['"])?:/g, '"$2": ');
  return JSON.parse(correctedString)
}

module.exports = {
  parse: parse,
}