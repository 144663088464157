var SubtitleChunkUtils = require('./SubtitleChunkUtils')

// Last message video position, mseconds from video begin.
var lastMessageTime = null

function sendStopMessage(pubnub, pubnubChannelId) {
  var message = {
    src: pubnub.getUUID(),
    type: 'stop',
    ver: 3
  }
  console.log(message)
  pubnub.publish({
      channel : pubnubChannelId,
      message : message,
  })
}

function getTimestamp(pubnub, callback) {
  pubnub.time(function(status, response) {
    if (status.error) {
      // handle error if something went wrong based on the status object
    } else {
      callback(response.timetoken)
    }
  })
}

function sendPlayMessage(nextSubtitles, pubnub, pubnubChannelId) {
  getTimestamp(pubnub, function (timetoken) {
    var message = {
      src: pubnub.getUUID(),
      subtitles: nextSubtitles,
      type: 'play',
      serverTime: timetoken/10000,
      ver: 3
    }
    //console.log(message)
    pubnub.publish({
      channel : pubnubChannelId,
      message : message,
    })
  })
}

function play(positionMsec, subtitles, pubnub, pubnubChannelId) {
  if (!subtitles || !subtitles.length) {
    // Stop button pressed.
    // We must send 0 to reset the devcice.
    sendStopMessage(pubnub, pubnubChannelId)
    // Re-send data chunk on next subtitle
    lastMessageTime = null
    return
  }

  // TODO we need to handle a case when there is a big gap in subtitles.
  // In this case we send subtitles for chunkSizeMsec but we don't send a subtitle
  // for the moment after the gap. In this case we'll have to send a chunk right after the gap
  // and it will probably skip the first sub on the client because of network delay.
  var timeBetweenChunksMsec = 60000 // time between sending chunks
  var chunkSizeMsec = 70000 // amount of subtitles in each chunk in mseconds
  if (lastMessageTime && lastMessageTime + timeBetweenChunksMsec > positionMsec) {
    // Skip messages that have been sent already
    return
  }

  lastMessageTime = positionMsec
  var nextSubtitles = SubtitleChunkUtils.getNextSubtitles(subtitles, positionMsec, chunkSizeMsec)
  sendPlayMessage(nextSubtitles, pubnub, pubnubChannelId)
}

function reset() {
  // Re-send data chunk on next subtitle
  lastMessageTime = null
}

module.exports = {
  play: play,
  reset: reset,
}
