/**
 * Subtitle player logger. Tracks time intervals when video has been player.
 * At the end of interval sends REST API request to add it to the DB.
 */
var zeptojs = require('zeptojs')

var intervalStarted = false

var settings = {
  apiUrl: '',
  apptoken: ''
}
var sessionId = null
var devices = []

function startInterval (currentTimeInMilliseconds) {
  intervalStarted = true
  writeIntervalStart(sessionId, currentTimeInMilliseconds, devices)
}

function endInterval () {
  intervalStarted = false
  writeIntervalEnd(sessionId)
}

function writeIntervalStart (sessionId, intervalStartPosMsec, devices) {
  sessionId = parseInt(sessionId)
  var url = settings.apiUrl + '/sessions/' + sessionId +
    '/start?apptoken=' + settings.apptoken
  var data = {
    start: intervalStartPosMsec/1000,
    devices: devices,
  }

  zeptojs.ajax({
    type: 'post',
    data: JSON.stringify(data),
    dataType: "json",
    contentType: 'application/json',
    url: url,
  })
}

function writeIntervalEnd (sessionId) {
  sessionId = parseInt(sessionId)
  var url = settings.apiUrl + '/sessions/' + sessionId +
    '/end?apptoken=' + settings.apptoken

  zeptojs.ajax({
    type: 'post',
    url: url,
  })
}

function init(_settings) {
  settings = _settings
}

function setSessionId(_sessionId) {
  sessionId = _sessionId
}

function devicesChanged (newDevices, currentVideoPosInMilliseconds) {
  var sameDeivices = (newDevices.length == devices.length) &&
    newDevices.every(function(element, index) {
      return element === devices[index]
    })

  if (sameDeivices) {
    return
  }

  devices = newDevices
  if (intervalStarted) {
    writeIntervalStart (sessionId, currentVideoPosInMilliseconds, devices)
  }
}

module.exports = {
  init: init,
  setSessionId: setSessionId,
  startInterval: startInterval,
  endInterval: endInterval,
  devicesChanged: devicesChanged,
}
