/**
 * DeviceWatch detects the moment when mobile app becomes online. It subscribes for PubNub presense
 * events and waits until the mobile app gets online.
 *
 * Before that moment subtitle loader doesn't load subtitles from Subtitles server and subtitle
 * player doesn't send them via PubNub.
 *
 * When the mobile app comes online, DeviceWatch sends notification to subscribers.
 *
 * The subscribers then continue with loading subtitles and start sending subtitle
 * events to the mobile app.
 */
var EventEmitter = require('events')
var zeptojs = require('zeptojs')
var Promise = require('promise')
var PubNub = require('pubnub')
var appsSettings = require('./apps/AppsSettings')

var CONNECT_EVENT = 'connect'
var emitter = new EventEmitter()
var deviceWasConnected = false

/**
 * Initialize PubNub object with parameters like
 * publish/subscribe keys, client uuid, etc.
 */
function initPubnub(settings) {
  var pubnubUUID = 'jsapi-watch-' + Math.random()
  var pubnub = new PubNub({
    subscribe_key : settings.PUBNUB_CONFIG.subscribe_key,
    publish_key: settings.PUBNUB_CONFIG.publish_key,
    // Should be unique
    uuid: pubnubUUID,
    // See https://www.pubnub.com/community/discussion/623/javascript-https-problem
    ssl : ('https:' === document.location.protocol),
    presenceTimeout: 300,
  })
  return pubnub
}

/**
 * Request PubNub channel ID from ControlPlane server
 */
function getPubnubChannel(settings) {
  return new Promise(function (resolve, reject) {
    var userId = settings.userId
    var url = settings.apiUrl + '/jslib-api/v1/user/' + userId +
      '/pubnub?partner_token=' + settings.partnerToken

    zeptojs.ajax({
      type: 'get',
      url: url,
      success: function (response) {
        try {
          var result = JSON.parse(response)
          var channel = result.pubnub_channel
          resolve(channel)
        } catch(e) {
          reject('Server responded with invalid JSON: ' + response)
        }
      },
      error: function (err) {
        console.error('Error loading drs settings from feel server: ', err)
        reject(err)
      }
    })
  })
}

/**
 * Subscribe to PubNub presense events in order to
 * detect the moment when mobile app gets online
 * @param pubnub - pubnub object
 * @param channel - pubnub channel id
 */
function subscribeForPresense (pubnub, channel) {
  var pubNubListener = null

  // Subscribe for channel notifications
  pubnub.subscribe({
    channels: [channel],
    withPresence: true,
  })

  var onDeviceDetected = function () {
    // Remove PubNub listeners and disconnect from PubNub
    pubnub.removeListener(pubNubListener)
    pubnub.unsubscribe({
      channels: [channel],
    })

    // Send messages that it's been connected
    deviceWasConnected = true
    emitter.emit(CONNECT_EVENT)
  }

  pubNubListener = {
    presence : function (m) {
      console.log('=== Presence: ', m)
      var uuid = m.uuid
      if (uuid.indexOf('mobile-client') === 0) {
        onDeviceDetected()
      }
    },
    message : function (m) {
      if (m.message.what === 'user-authentication') {
        // This message is sent by ControlPlane server
        // at the moment when QR code has been scanned by this user
        console.log('User authentication detected')
        onDeviceDetected()
      }
    }
  }

  pubnub.addListener(pubNubListener)

  // Check if the mobile app is online already
  pubnub.hereNow({
      channels: [channel],
      includeUUIDs: true,
    },
    function (status, response) {
      var occupants = response.channels[channel].occupants
      console.log('hereNow result: ', occupants)
      for (var i = 0; i < occupants.length; i++) {
        var uuid = occupants[i].uuid
        if (uuid.indexOf('mobile-client') === 0) {
          onDeviceDetected()
        }
      }
    }
  )
}

/**
 * Initialize DeviceWatch
 * @param feelAppsToken - ControlPlane token
 * @param userId - current user ID
 */
function init (feelAppsToken, userId) {
  // TODO test init/destroy scenario
  if (appsSettings.partnerToken || appsSettings.userId) {
    throw "Error: $feel library has been already initialized. Please call $feel.destroy() before initializing it again."
  }
  // TODO move to AppsSettings
  appsSettings.partnerToken = feelAppsToken
  appsSettings.userId = userId

  var pubnub = initPubnub(appsSettings)
  return getPubnubChannel(appsSettings).then(function (channel) {
    return subscribeForPresense(pubnub, channel)
  }).catch(function (error) {
    console.error(error)
  })
}

/**
 * Subscribe callback function for mobile app connection event
 */
function onDeviceConnected (callback) {
  emitter.on(CONNECT_EVENT, callback)
}

/**
 * Return flag indicating if mobile app has been connected
 */
function wasDeviceConnected () {
  return deviceWasConnected
}

module.exports = {
  init: init,
  onDeviceConnected: onDeviceConnected,
  wasDeviceConnected: wasDeviceConnected,
}