var Promise = require('promise')
var PlayerLogic = require('./PlayerLogic')
var Loader = require('./Loader')
var Logger = require('./Logger')
var Parser = require('./Parser')
var BillingPubnub = require('./BillingPubnub')
var DeviceWatch = require('../DeviceWatch')

var EventEmitter = require('events')
var emitter = new EventEmitter()
var STATUS_EVENT_NAME = 'subtitle'

var initialized = false
var onSubtitle = null
// Timout to stop playing subtitles if we are not getting timeupdate events
var watchDogTimeout = null
// Flag indicating that we have paused subs because we didn't get timeupdate events
// for a certain time
var buffering = false
var playing = false

// We track current video position in order to detect
// video seek events
var previousPos = 0
// We consider that seek event has happened if current position
// differs from previous position for more that 2 seconds.
var seekDistance = 2.0

function checkInitialized() {
  if (!initialized) {
    throw('Please call $feel.init before loading/playing subtitles')
  }
}

/**
 * Check if current video position has changed for more than seekDistance
 * If yes, then send stop and start commands and return true
 * If not, return false
 * @param {*number} currentVideoPosInSeconds
 * @return true if seek event has happened, false otherwise.
 */
function handleVideoSeekEvent(currentVideoPosInSeconds) {
  if (Math.abs(currentVideoPosInSeconds - previousPos) > seekDistance) {
    // This is a hack for Pornhub player which doesn't
    // send pause/play event when seeking to another postion
    var wasPlaying = playing
    stop()
    if (wasPlaying) {
      play (currentVideoPosInSeconds)
    }
    previousPos = currentVideoPosInSeconds
    return true
  }
  previousPos = currentVideoPosInSeconds
  return false
}

function playSubtitle(subtitleObj, positionMsec, subtitles) {
  var percentValue = subtitleObj.subtitle * 25
  emitter.emit(STATUS_EVENT_NAME, percentValue)
  onSubtitle(percentValue, positionMsec, subtitles)
}

/**
 * Send 0 to the device to stop it vibrating/reset motors
 */
function resetDevice() {
  // Pass empty subtitle list
  playSubtitle({subtitle: '0'}, 0, [])
}

/**
 * Play subtitle
 * @param currentVideoPosInSeconds - current video position, sec
 */
function play (currentVideoPosInSeconds) {
  if (DeviceWatch.wasDeviceConnected()) {
    checkInitialized()
    var currentVideoPosInMilliseconds = Math.floor(currentVideoPosInSeconds*1000)
    PlayerLogic.play(currentVideoPosInMilliseconds, playSubtitle)
    Logger.startInterval(currentVideoPosInMilliseconds)
    BillingPubnub.play()
  }
  playing = true
}

/**
 * Update subtitle play position
 * @param currentVideoPosInSeconds - new video position, sec
 */
function timeupdate (currentVideoPosInSeconds) {
  if (!DeviceWatch.wasDeviceConnected()) {
    return
  }
  checkInitialized()

  if (handleVideoSeekEvent(currentVideoPosInSeconds)) {
    return
  }

  var currentVideoPosInMilliseconds = Math.floor(currentVideoPosInSeconds*1000)
  if (buffering) {
    // Subtitles have been paused because we didn't get timeupdate for a
    // quite some time. Now we must resume them.
    buffering = false
    PlayerLogic.play(currentVideoPosInMilliseconds, playSubtitle)
  } else {
    // Update current time for subtitles
    PlayerLogic.timeupdate(currentVideoPosInMilliseconds, playSubtitle)
  }

  clearTimeout(watchDogTimeout)
  if (playing) {
    // If we don't receive timeupdate within 1 seconds that means the video is not
    // playing because of some reason.
    // So we must pause playing subtitles.
    watchDogTimeout = setTimeout(function () {
      PlayerLogic.stop()
      buffering = true
    }, 1000)
  }
}

/**
 * Stop playing subtitle
 */
function stop () {
  if (DeviceWatch.wasDeviceConnected()) {
    checkInitialized()
    PlayerLogic.stop()
    resetDevice()
    Logger.endInterval()
    clearTimeout(watchDogTimeout)
  }
  playing = false
}

/**
 * Load subtitle from server and remember it. As soon as it's loaded, it's
 * ready to be played.
 * @param videoId - video external id, string
 * @param subtitlesId - subtitle id, integer
 * @param externalUserId - user id in the external system, string. Needed
 *        to collect subtitles usage statistics, can be null.
 * @param channel - channel id, string. Needed to collect subtitles usage
 *        statistics.
 * @return promise which is resolved when subtitle is loaded.
 */
function load (videoId, subtitlesId, externalUserId, channel) {
  if (channel === undefined) {
    channel = ''
  }

  return new Promise(function (resolve, reject) {
    DeviceWatch.onDeviceConnected(function () {
      checkInitialized()
      Loader.loadSubtitlesInfo(videoId, subtitlesId, externalUserId, channel)
        .then(function (subtitles) {
          // Configure logger
          var sessionId = subtitles['session_id']
          Logger.setSessionId(sessionId)

          // Configure subtitle player
          var subsText = subtitles.text
          var subtitiesObj = Parser.parse(subsText)
          PlayerLogic.setSubtitles(subtitiesObj)
          if (playing) {
            PlayerLogic.play(0, playSubtitle)
          }

          resolve()
        }).catch(function (error) {
          reject(error)
        })
    })
  })
}

function devicesChanged (devices) {
  var currentVideoPosInMilliseconds = PlayerLogic.getCurrentVideoPosition()
  Logger.devicesChanged(devices, currentVideoPosInMilliseconds)
}

function init (settings, onPlaySubtitle) {
  Loader.init(settings)
  Logger.init(settings)
  onSubtitle = onPlaySubtitle
  initialized = true
}

/**
 * Pubnub channel is needed to report to the billing in case if user
 * closed the browser. In this case PubNub will generate timeout event
 */
function setPubNub(pubnub) {
  BillingPubnub.init(pubnub)
}

module.exports = {
  load: load,
  play: play,
  stop: stop,
  timeupdate: timeupdate,
  init: init,
  devicesChanged: devicesChanged,
  setPubNub: setPubNub,
  events: emitter,
}