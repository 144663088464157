var apps = require('./apps/Apps')
var subs = require('./subs/Subs')
var MobileApi = require('./MobileApi')
var DeviceWatch = require('./DeviceWatch')

var SUBS_API_URL = 'https://api.pibds.com/api/v1'

function createPubnubUUID () {
  return 'jsapi-lib-' + Math.random()
}

function init(feelSubsToken, feelAppsToken, userId) {
  DeviceWatch.init(feelAppsToken, userId)
  DeviceWatch.onDeviceConnected(function () {
    var pubnubUUID = createPubnubUUID()
    var subsSettings = {
      apiUrl: SUBS_API_URL,
      apptoken: feelSubsToken,
      pubnubUUID: pubnubUUID,
    }
    subs.init(subsSettings, apps.playSubtitle)
    apps.init(feelAppsToken, userId, onDevicesChanged, pubnubUUID)
  })
}

function onDevicesChanged (devices) {
  subs.devicesChanged(devices)
}

function initMobile(feelSubsToken) {
  var subsSettings = {
    apiUrl: SUBS_API_URL,
    apptoken: feelSubsToken,
    pubnubUUID: '',
  }
  subs.init(subsSettings, MobileApi.playSubtitle)
}

function initSlider(feelAppsToken, userId) {
  DeviceWatch.init(feelAppsToken, userId)
  DeviceWatch.onDeviceConnected(function () {
    var pubnubUUID = createPubnubUUID()
    apps.init(feelAppsToken, userId, null, pubnubUUID)
  })
}

function destroy () {
  apps.destroy()
}

module.exports = {
  /**
   * Initialize subtitles and mobile app connection
   * @param feelSubsToken - Feel Subtitles access token
   * @param feelAppsToken - Feel Apps access token
   * @param userId - Feel Apps user ID
   */
  init: init,

  /**
   * Initialize library to play on pages opened inside Feel app
   */
  initMobile: initMobile,

  /**
   * Initialize mobile app connection
   * @param feelAppsToken - Feel Apps access token
   * @param userId - Feel Apps user ID
   */
  initSlider: initSlider,

  /**
   * Close all connections, release internal resources,
   * @note this function should be called before calling init / initSlider / initMobile once again.
   */
  destroy: destroy,

  /**
   * Return app connection object
   */
  apps: apps,

  /**
   * Return subtitles player object
   */
  subs: subs,
}
