/**
 * Filter array of messages with percent values. Remove all intermediate values
 * so that only extremes are left.
 * Each message is
 * {
 *    value: <value>,
 *    somethingElse: ...,
 * }
 *
 * e.g.
 *  filterIntermediateValues([0, 50, 100, 75, 50, 75, 100]) === [0, 100, 50, 100]
 */
function filterIntermediateValues (percentValuesArray) {
  if (percentValuesArray.length <= 1) {
    return percentValuesArray
  }

  var result = [percentValuesArray[0]]
  for (var i = 1; i < percentValuesArray.length - 1; i++) {
    var prev = percentValuesArray[i - 1]
    var current = percentValuesArray[i]
    var next = percentValuesArray[i + 1]
    if ((prev.value <= current.value && current.value <= next.value) ||
        (prev.value >= current.value && current.value >= next.value)) {
      continue
    }

    result.push(current)
  }
  result.push(percentValuesArray[percentValuesArray.length - 1])
  return result
}

module.exports = {
  filterIntermediateValues: filterIntermediateValues,
}