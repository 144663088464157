function playSubtitle (percentValue) {
  console.log('subtitle percent value: ', percentValue)
  window.parent.postMessage({
      what: 'write',
      percent: percentValue
  }, '*');
}

module.exports = {
  playSubtitle: playSubtitle
}