var videoStartTimeMillis = 0
var nextSubtitleTimeout = null
var subtitles = []

function timeupdate (pos, onSubtitle) {
  // Update playing time only if we are playing right now
  if (!nextSubtitleTimeout) {
    return
  }

  play(pos, onSubtitle)
}

function play (pos, onSubtitle) {
  videoStartTimeMillis = (new Date()).getTime() - pos
  clearTimeout(nextSubtitleTimeout)
  nextSubtitleTimeout = null

  var nextSubtitle = findNextSubtitle(pos)
  if (!nextSubtitle) {
    // We are done
    return
  }

  var timeout = nextSubtitle.time - pos
  var onSubtitleFn = onSubtitle
  nextSubtitleTimeout = setTimeout(function () {
    // play subtitle
    var now = (new Date()).getTime()
    var newPos = now - videoStartTimeMillis
    onSubtitleFn(nextSubtitle, newPos, subtitles)
    play(newPos, onSubtitleFn)
  }, timeout)
}

function findNextSubtitle (pos) {
  // TODO This is O(N) algo. Change to O(log(N))
  for (var i = 0; i < subtitles.length; i++) {
    var subtitle = subtitles[i]
    if (subtitle.time > pos) {
      return subtitle
    }
  }
  return null
}

function stop () {
  clearTimeout(nextSubtitleTimeout)
  nextSubtitleTimeout = null
}

// Object with key: seconds and values: kiiroo value
function setSubtitles (subtitiesObj) {
  subtitles = []

  // parse them
  for (var time in subtitiesObj) {
    subtitles.push({
      time: time * 1000, // keep time in milliseconds
      subtitle: subtitiesObj[time]
    })
  }

  // Sort by time
  subtitles.sort(function (a, b) {
    return a.time - b.time
  })
}

/**
 * Return current video position in milliseconds
 */
function getCurrentVideoPosition () {
  var pos = (new Date()).getTime() - videoStartTimeMillis
  return pos
}

module.exports = {
  play: play,
  stop: stop,
  timeupdate: timeupdate,
  setSubtitles: setSubtitles,
  getCurrentVideoPosition: getCurrentVideoPosition,
}
