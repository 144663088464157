var Promise = require('promise')
var zeptojs = require('zeptojs')

var statsApiHost = 'https://stats.feeltechnology.com'

var settings = {
  apiUrl: '',
  apptoken: ''
}

function getRelativePath(url) {
  // FIXME: There is a way to do that using document, which is not available in all JS environments
  const relativePath = url.replace(settings.apiUrl, '');
  const parametersIndex = relativePath.indexOf('?');
  if (parametersIndex > -1) {
    return relativePath.substr(0, parametersIndex);
  }

  return relativePath;
}

function postRequestStats(url, videoId, externalUserId, path, status, message) {
  const apiUrl = statsApiHost + '/api/v1/subtitle-requests/';

  zeptojs.ajax({
    type: 'POST',
    url: apiUrl,
    contentType: 'application/json',
    data: JSON.stringify({
      endpoint: 'subtitlerequests',
      path: path,
      method: 'POST',
      user_id: externalUserId,
      video_id: videoId,
      status_code: status,
      message: message,
    }),
  });
}

function loadSubtitlesInfo (videoId, subtitlesId, externalUserId, channel) {
  subtitlesId = parseInt(subtitlesId)
  var url = settings.apiUrl + '/videos/' + encodeURIComponent(videoId) +
    '/subtitles/' + subtitlesId + '?apptoken=' + settings.apptoken
  return new Promise(function (resolve, reject) {
    zeptojs.ajax({
      type: 'get',
      url: url,
      data: {
        external_user_id: externalUserId,
        channel: channel,
        pubnub_uuid: settings.pubnubUUID,
      },
      success: function (response) {
        try {
          var subsList = JSON.parse(response)
          resolve(subsList)
        } catch(e) {
          reject('Server responded with invalid JSON: ' + response)
        }
      },
      error: reject,
      complete: function(xhr, status) {
        var relativePath = getRelativePath(xhr.responseURL)
        postRequestStats(url, videoId, externalUserId, relativePath, xhr.status, status);
      },
    })
  })
}

module.exports = {
  init: function(_settings) {
    settings = _settings
  },

  loadSubtitlesInfo: loadSubtitlesInfo
}
